import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';
import classNames from 'classnames';
import ViewManager from './ViewManager';

function Configurator({
	model,
	setModel,
	modelTop, setModelTop,
	modelTopColor, setModelTopColor,
	modelStructureColor, setModelStructureColor,
	modelBottom, setModelBottom,
	selectedData
}) {
	const [sceneObjects, setSceneObjects] = useState(new Map());
	const [showControls, setShowControls] = useState(false);
	const [showLoader, setShowLoader] = useState([]);
	const [views, setViews] = useState([]);
	const controlsRef = useRef(null);
	const sceneRef = useRef(null);
	const cameraRef = useRef(null);
	const rendererRef = useRef(null);
	const [loader] = useState(new GLTFLoader());
	const loaderRef = useRef(null);
	const configuratorMediaRef = useRef(null);

	const getViews = useCallback(() => {
		if (!sceneRef.current || !cameraRef.current || !rendererRef.current) {
			return;
		}
		const mainModel = sceneRef.current.getObjectByName('main');
		if (!mainModel) return;

		const newViews = [
			{ position: new THREE.Vector3(42, 3.5, 2.5), lookAtObject: 'main' },
			{ position: new THREE.Vector3(11, 34, 0), lookAtObject: 'top_group' },
			{ position: new THREE.Vector3(28, -10, 3), lookAtObject: 'top_group' },
			{ position: new THREE.Vector3(12.3, -2.8, 11), lookAtObject: 'bottom' }
		];

		const viewCamera = new THREE.PerspectiveCamera(100, rendererRef.current.domElement.clientWidth / rendererRef.current.domElement.clientHeight, 0.1, 1000);

		const thumbnails = newViews.map(view => {
			let toObject = mainModel.getObjectByName(view.lookAtObject) || mainModel;
			const boundingBox = new THREE.Box3().setFromObject(toObject);
			const objectCenter = boundingBox.getCenter(new THREE.Vector3());
			viewCamera.position.copy(view.position);
			viewCamera.lookAt(objectCenter);
			rendererRef.current.render(sceneRef.current, viewCamera);

			return {
				thumbnail: rendererRef.current.domElement.toDataURL(),
				position: view.position,
				target: objectCenter
			};
		});

		setViews(thumbnails);
	}, []);

	const handleResize = useCallback(() => {
		const width = configuratorMediaRef.current.clientWidth;
		const height = configuratorMediaRef.current.clientHeight;
		rendererRef.current.setSize(width, height)
		cameraRef.current.aspect = width / height;
		cameraRef.current.updateProjectionMatrix();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
	  
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, [handleResize]);

	useEffect(() => {
		if (configuratorMediaRef.current) {
			sceneRef.current = new THREE.Scene();
			cameraRef.current = new THREE.PerspectiveCamera(100, configuratorMediaRef.current.clientWidth / configuratorMediaRef.current.clientHeight, 0.1, 1000);
			cameraRef.current.position.set(30, 11, -31);

			rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
			rendererRef.current.setSize(configuratorMediaRef.current.clientWidth, configuratorMediaRef.current.clientHeight);
			rendererRef.current.toneMapping = THREE.ACESFilmicToneMapping;
			rendererRef.current.toneMappingExposure = 1.25;
			configuratorMediaRef.current.appendChild(rendererRef.current.domElement);

			sceneRef.current.add(new THREE.AmbientLight(0xffffff, 1));
			const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
			directionalLight.position.set(0, 100, 100);
			directionalLight.castShadow = true;
			sceneRef.current.add(directionalLight);
			const pointLight = new THREE.PointLight(0xffffff, 1, 100);
			pointLight.position.set(50, 50, 50);
			sceneRef.current.add(pointLight);

			sceneRef.current.background = new THREE.Color(0xfefefe);

			controlsRef.current = new OrbitControls(cameraRef.current, rendererRef.current.domElement);
			controlsRef.current.enableDamping = true;
			controlsRef.current.dampingFactor = 0.25;
			controlsRef.current.rotateSpeed = 0.35;
			controlsRef.current.enableZoom = true;
			controlsRef.current.zoomSpeed = 1.2;

			const animate = () => {
				requestAnimationFrame(animate);
				rendererRef.current.render(sceneRef.current, cameraRef.current);
				TWEEN.update();
			};
			animate();
			handleResize();


			return () => {
				if (configuratorMediaRef.current && rendererRef.current) {
					configuratorMediaRef.current.removeChild(rendererRef.current.domElement);
				}
			};
		}
	}, []);

	useEffect(() => { // Select Model 
		if (model.drawing != "") {
			// console.log('reMain');
			loadModel(model.drawing, "main", selectedData)
			updateTopFabricColor(modelTopColor);
		}
	}, [model]);

	useEffect(() => { // Change Top Model 
		if (modelTop.drawing != "") {
			// console.log('reTop');
			loadModel(modelTop.drawing, "top")
		} else {
			if (model.drawing != "") {
				loadBasePart('top')
			}
		}
	}, [modelTop]);

	useEffect(() => { // Change Top Fabric color
		updateTopFabricColor(modelTopColor);
	}, [modelTopColor]);

	useEffect(() => {// Change color on "material_structure"  on click
		if (!!!sceneObjects.get('main')) { return; }
		changeMaterial(sceneObjects.get('main'), 'material_structure', modelStructureColor.color, modelStructureColor.pattern)

	}, [modelStructureColor]);


	useEffect(() => {// Change Bottom Model
		if (modelBottom.drawing != "") {
			loadModel(modelBottom.drawing, "bottom")
		} else {
			if (model.drawing != "") {
				loadBasePart('bottom')
			}
		}
	}, [modelBottom]);

	const updateTopFabricColor = (modelTopColor) => {
		if (modelTopColor.color != "" && modelTopColor.color != null) {
			modifyTopFabric(modelTopColor.color)
			return;
		}

		if (modelTopColor.color === null) {
			modifyTopFabric(null, modelTopColor.pattern)
			return;
		}
		// console.log("updateTopFabricColor NO_DATA" );
	}

	const loadModel = (path, modelName) => {
		setShowLoader(true);
		setShowControls(false);
		console.log(path);
		console.log(modelName);

		loader.load(path,
			(gltf) => {
				const object = gltf.scene;
				object.name = modelName;

				if (modelName === "main") {
					const boundingBox = new THREE.Box3().setFromObject(object);
					const objectCenter = boundingBox.getCenter(new THREE.Vector3());
					object.position.sub(objectCenter);

					
					removeIfExist(modelName);
					sceneRef.current.add(object);
					console.log("object");
					console.log(object);

					setSceneObjects(prev => new Map([...prev, [modelName, object]]));
					mainTopBottomObjectGrouping(object);

					setModelTop(prevState => ({
						...prevState,
						name: selectedData.parts.top[0].name,
						drawing: selectedData.parts.top[0].model
					}))

					setModelTopColor(prevState => ({ ...prevState }))
					setModelBottom(prevState => ({
						...prevState,
						drawing: selectedData.parts.bottom[0].models[0].model,
						name: selectedData.parts.bottom[0].models[0].name
					}));

					setModelStructureColor(prevState => ({
						...prevState,
						color: selectedData.colors.main.colors[0].color,
						name: selectedData.colors.main.colors[0].name,
						pattern: selectedData.colors.main.colors[0].pattern
					}));
				}

				if (modelName === "top") {
					const topGroup = sceneObjects.get('main').getObjectByName('top_group');
					const baseTopModel = topGroup.children[0];

					const boundingBox = new THREE.Box3().setFromObject(baseTopModel);
					const center = boundingBox.getCenter(new THREE.Vector3());

					topGroup.children.slice(1).forEach(child => topGroup.remove(child));
					topGroup.worldToLocal(center);

					object.position.set(0, 0, 0);
					object.rotation.set(0, 0, 0);
					object.scale.set(1, 1, 1);
					const objectBoundingBox = new THREE.Box3().setFromObject(object);
					const objectCenter = objectBoundingBox.getCenter(new THREE.Vector3());
					object.position.copy(center).sub(objectCenter);


					baseTopModel.visible = false;
					topGroup.add(object);
					sceneRef.current.updateMatrixWorld(true);

					updateTopFabricColor(modelTopColor);
					changeMaterial(sceneObjects.get('main'), 'material_structure', modelStructureColor.color, modelStructureColor.pattern)
				}


				if (modelName === "bottom") {
					const bottomGroup = sceneObjects.get('main').getObjectByName('bottom_group');
					const baseBottomModel = bottomGroup.children[0];

					const boundingBox = new THREE.Box3().setFromObject(baseBottomModel);
					const center = boundingBox.getCenter(new THREE.Vector3());
					const topCenter = new THREE.Vector3(center.x, boundingBox.max.y, center.z);

					bottomGroup.children.slice(1).forEach(child => bottomGroup.remove(child));
					bottomGroup.worldToLocal(topCenter);

					object.position.set(0, 0, 0);
					object.rotation.set(0, 0, 0);
					object.scale.set(1, 1, 1);
					const objectBoundingBox = new THREE.Box3().setFromObject(object);
					const objectTopCenter = new THREE.Vector3(
						(objectBoundingBox.min.x + objectBoundingBox.max.x) / 2,
						objectBoundingBox.max.y,
						(objectBoundingBox.min.z + objectBoundingBox.max.z) / 2
					);

					const offset = new THREE.Vector3().subVectors(topCenter, objectTopCenter);
					object.position.copy(offset);


					baseBottomModel.visible = false;
					bottomGroup.add(object);
					sceneRef.current.updateMatrixWorld(true);

					changeMaterial(sceneObjects.get('main'), 'material_structure', modelStructureColor.color, modelStructureColor.pattern)
				}

				getViews();
				setShowControls(true);
			}, (xhr) => {
				if (loaderRef.current) {
					loaderRef.current.querySelector(".loader__text").innerHTML = `${((xhr.loaded / xhr.total) * 100).toFixed(1)}% loaded`;
				}

				if (xhr.loaded === xhr.total) {
					setShowLoader(false)
					setShowControls(true);

					setTimeout(() => { // Clear Content 
						loaderRef.current.querySelector(".loader__text").innerHTML = ``;
					}, 1000);
				}
			}, (error) => console.error(error)
		);
	};

	const removeIfExist = (name) => {
		const existingObject = sceneRef.current.getObjectByName(name);
		if (existingObject) {
			sceneRef.current.remove(existingObject);
		}
	};

	const modifyTopFabric = (colorHex, texturePath) => {
		if (sceneObjects.size == 0) { return };

		const topGroup = sceneObjects.get('main').getObjectByName('top_group');
		const textureLoader = new THREE.TextureLoader();
		let texturesLoaded = 0;
		const totalTextures = topGroup.children.length;

		topGroup.children.forEach((topGroupChild) => {
			const topFabric = topGroupChild.getObjectByName('top_fabric');
			if (topFabric) {
				const geometry = topFabric.geometry;
				let materialOptions = {
					color: 0xffffff,
					roughness: 0.7,
					metalness: 0,
					side: THREE.DoubleSide
				};

				const positions = geometry.attributes.position;
				const uvs = new Float32Array(positions.count * 2);

				for (let i = 0; i < positions.count; i++) {
					uvs[i * 2] = positions.getX(i) / 50 + 0.5;
					uvs[i * 2 + 1] = positions.getY(i) / 50 + 0.5;
				}

				geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

				if (colorHex) {
					materialOptions.color = new THREE.Color(colorHex);
				}

				if (texturePath) {
					textureLoader.load(texturePath, (texture) => {
						texture.generateMipmaps = true;
						texture.minFilter = THREE.LinearMipmapLinearFilter;

						if (rendererRef && rendererRef.capabilities) {
							texture.anisotropy = rendererRef.capabilities.getMaxAnisotropy();
						} else {
							console.warn('Renderer or renderer capabilities not available. Skipping anisotropy setting.');
						}

						const box = new THREE.Box3().setFromObject(topFabric);
						const size = new THREE.Vector3();
						box.getSize(size);
						const repeatX = size.x / 5;
						const repeatZ = size.z / 5;
						texture.repeat.set(repeatX, repeatZ);
						texture.wrapS = THREE.RepeatWrapping;
						texture.wrapT = THREE.RepeatWrapping;
						materialOptions.map = texture;

						topFabric.material = new THREE.MeshStandardMaterial(materialOptions);

						texturesLoaded++;
						if (texturesLoaded === totalTextures) {
							getViews();
						}
					});
				} else {
					topFabric.material = new THREE.MeshStandardMaterial(materialOptions);

					texturesLoaded++;
					if (texturesLoaded === totalTextures) {
						getViews();
					}
				}

				if (topFabric.material.map) {
					topFabric.material.map.dispose();
				}
			}
		});
	};

	const mainTopBottomObjectGrouping = (object) => {
		const topBase = object.getObjectByName('top');
		const topGroup = new THREE.Group();
		topGroup.name = "top_group";
		topGroup.add(topBase);
		object.add(topGroup);

		const bottomBase = object.getObjectByName('bottom');
		const bottomGroup = new THREE.Group();
		bottomGroup.name = "bottom_group";
		bottomGroup.add(bottomBase);
		object.add(bottomGroup);
	}

	const changeMaterial = (scene, materialName, colorHex, texturePath, customRepeatFactor, reset = false) => {
		const materials = getMateriaUsedInObject(scene, materialName);
		const textureLoader = new THREE.TextureLoader();

		const determineRepeatFactor = (object) => {
			const box = new THREE.Box3().setFromObject(object);
			const size = box.getSize(new THREE.Vector3());
			const maxDimension = Math.max(size.x, size.y, size.z);
			return maxDimension / 2;
		};

		const adjustUVs = (geometry) => {
			if (geometry.attributes.uv) {
				const uvAttribute = geometry.attributes.uv;
				for (let i = 0; i < uvAttribute.count; i++) {
					uvAttribute.setXY(i, uvAttribute.getX(i) / 2, uvAttribute.getY(i) / 2);
				}
				uvAttribute.needsUpdate = true;
			}
		};

		const findObjectByMaterial = (object, targetMaterial) => {
			if (object.material === targetMaterial) {
				return object;
			}
			if (object.children) {
				for (let child of object.children) {
					const found = findObjectByMaterial(child, targetMaterial);
					if (found) return found;
				}
			}
			return null;
		};

		const resetMaterial = (material) => {
			material.color.setHex(0xffffff);
			material.roughness = 0.5;
			material.metalness = 0.5;
			material.side = THREE.FrontSide;
			if (material.map) {
				material.map.dispose();
				material.map = null;
			}
			material.needsUpdate = true;
		};

		materials.forEach((material) => {
			const object = findObjectByMaterial(scene, material);
			if (!object) {
				console.warn(`No object found for material ${materialName}`);
				return;
			}

			if (reset) {
				resetMaterial(material);
				return;
			}

			const baseColor = colorHex ? new THREE.Color(colorHex) : new THREE.Color("#ddd");

			let newMaterial = new THREE.MeshStandardMaterial({
				color: baseColor,
				roughness: material.roughness || 0.7,
				metalness: material.metalness || 0,
				side: material.side || THREE.DoubleSide,
				name: materialName
			});

			if (texturePath) {
				textureLoader.load(texturePath, (texture) => {
					texture.generateMipmaps = true;
					texture.minFilter = THREE.LinearMipmapLinearFilter;

					if (rendererRef && rendererRef.capabilities) {
						texture.anisotropy = rendererRef.capabilities.getMaxAnisotropy();
					} else {
						console.warn('Renderer or renderer capabilities not available. Skipping anisotropy setting.');
					}

					const repeatFactor = customRepeatFactor || determineRepeatFactor(object);
					texture.repeat.set(repeatFactor, repeatFactor);
					texture.wrapS = THREE.RepeatWrapping;
					texture.wrapT = THREE.RepeatWrapping;

					newMaterial.map = texture;
					newMaterial.color = baseColor;
					applyNewMaterial();
				});
			} else {
				applyNewMaterial();
			}

			function applyNewMaterial() {
				if (material.map) material.map.dispose();
				material.dispose();

				Object.assign(material, newMaterial);

				if (!texturePath) {
					material.map = null;
				}

				if (object.geometry) {
					adjustUVs(object.geometry);
				}

				if (texturePath && !material.map) {
					console.warn(`Texture not applied correctly to ${materialName}`);
				}

				material.needsUpdate = true;
			}
		});

		setTimeout(() => {
			getViews();
		}, 500);
	};

	const getMateriaUsedInObject = (object, materialName) => {
		const materials = new Set();
		function traverseMaterials(object) {
			if (object.material && object.material.name.includes(materialName)) {
				if (Array.isArray(object.material)) {
					object.material.forEach(material => materials.add(material));
				} else {
					materials.add(object.material);
				}
			}
			if (object.children) {
				object.children.forEach(child => traverseMaterials(child));
			}
		}

		traverseMaterials(object);

		return Array.from(materials);
	}

	const loadBasePart = (pathName) => {
		if (pathName != "top" && pathName != "bottom") {
			return;
		}
		const group = sceneObjects.get('main').getObjectByName(`${pathName}_group`);

		group.children.forEach((el, index) => {
			if (index === 0) {
				el.visible = true;
			} else {
				group.remove(el);
			}
		});

		getViews();
	};

	return (
		<div
			ref={configuratorMediaRef}
			style={{ width: '100%', height: '100%' }}
			className={classNames('canvas-holder', { 'is-visible': showControls })}
		>
			<div
				ref={loaderRef}
				className={classNames('loader', { 'is-visible': showLoader })}
			>
				<span className="loader__text"></span>
			</div>

			<ViewManager
				views={views}
				controls={controlsRef.current}
				camera={cameraRef.current}
			/>
		</div>
	);
}

export default Configurator;