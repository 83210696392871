import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

function ChoiceBottomModel({ selectedData, setModelBottom}) {
	const [activeIndex, setActiveIndex] = useState(false);

	useEffect(() => {//Reset Selection on model change 
		if (selectedData.length === 0 ) { return }
		setActiveIndex(false)
    }, [selectedData]);

	const handleModelSelection = (path, name, itemIndex) => {
		setActiveIndex(itemIndex);

		setModelBottom(prevState => ({ 
			...prevState, 
			name: name, 
			drawing: path 
		}));
	}

	if (!selectedData || !selectedData.parts || !selectedData.parts.top || !Array.isArray(selectedData.parts.top)) {
		return null;
	}

	if (selectedData.parts.top.length === 0) {
		return null;
	}

	return (
		<div className="choices">
			{selectedData.parts.bottom.map((group, indexGrop) => (
				<div key={indexGrop}>
					<h6>{group.groupName}</h6>
					
					<ul>
						{group.models.map((item, index) => (
							<li key={index}>
								<a
									onClick={(e) => {
										e.preventDefault();
										const itemIndex = indexGrop+"_"+index
										handleModelSelection(item.model, item.name, itemIndex)
									}}
									className={classNames('preview decor-checkbox', { 'is-active': activeIndex === indexGrop+"_"+index || ((item.model === "") && !activeIndex) })}
									href={item.model}
								>
									<picture className="preview__media">
										<img src={item.preview} alt={item.name} />
									</picture>

									<div className="preview__content">
										<h6>{item.name}</h6>
									</div>
								</a>
							</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
}

export default ChoiceBottomModel;