import React, { useState, useEffect } from 'react';
import ChoiceDistributor from './ChoiceDistributor';
import Titles from './Titles';
import WidgetActions from './WidgetActions';
import Configurator from './Configurator';

function ConfiguratorPage() {
	const [items, setItems] = useState([]);
	const [currentStep, setCurrentStep] = useState(1);
	const [selectedData, setSelectedData] = useState([]);
	const [model, setModel] = useState({ drawing: "", name: "" });
	const [modelTop, setModelTop] = useState({ drawing: "", name: "" });
	const [modelTopColor, setModelTopColor] = useState({ color: "", pattern: "", name: "" });
	const [modelStructureColor, setModelStructureColor] = useState({ color: "", pattern: "", name: "" });
	const [modelBottom, setModelBottom] = useState({ drawing: "", name: "" });

	useEffect(() => {
		const newItems = {
			titles: ["Chose a Model", "Chose a Shape", "Chose Canopy Color or Pattern", "Chose Structure Color", "Chose а Base"],
			models: [
				{
					name: "Model 1",
					image: window.resoursesPath+"/images/choices/image_30.png",
					model: window.homeUrl+"?model=model-1",
					parts: {
						top: [
							{
								name: "Base",
								model: "",
								preview: window.resoursesPath+"/images/choices/top-2.png"
							},
							{
								name: "Square",
								model: window.homeUrl+"?model=top-1",
								preview: window.resoursesPath+"/images/choices/top-2.png"
							},
							{
								name: "Round",
								model: window.homeUrl+"?model=top-2",
								preview: window.resoursesPath+"/images/choices/top-1.png"
							}
						],
						bottom: [
							{
								groupName: "No Fixed Base",
								models: [
									{
										name: "Base",
										model: "",
										preview: window.resoursesPath+"/images/choices/bottom-3.jpg"
									},
									{
										name: "Round",
										model: window.homeUrl+"?model=bottom-2",
										preview: window.resoursesPath+"/images/choices/bottom-2.jpg"
									}
								]
							},
							{
								groupName: "Fixed Base",
								models: [
									{
										name: "Bold Down Base",
										model: window.homeUrl+"?model=bottom-1",
										preview: window.resoursesPath+"/images/choices/bottom-1.jpg"
									}
								]
							}
						]
					},
					colors: {
						top: {
							colors: [
								{
									color:"#a80c15",
									name: "Crimson Red"
								},
								{
									color:"#cb5b39",
									name: "Burnt Sienna"
								},
								{
									color:"#e76f18",
									name: "Pumpkin Orange"
								},
								{
									color:"#38191f",
									name: "Dark Burgundy"
								},
								{
									color:"#592f37",
									name: "Deep Maroon"
								},
								{
									color:"#f3c501",
									name: "Golden Yellow"
								},
								{
									color:"#e4e4e4",
									name: "Light Gray"
								}
							],
							patterns: [
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-1.jpg",
									name: "Pattern 1"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-2.jpg",
									name: "Pattern 2"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-3.jpg",
									name: "Pattern 3"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-4.jpg",
									name: "Pattern 4"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-5.jpg",
									name: "Pattern 5"
								}
							]
						},
						main: {
							colors: [
								{
									color:"#f2f0f1",
									name: "Off White"
								},
								{
									color:"#aea5a6",
									name: "Light Taupe"
								},
								{
									color:"#9b7b66",
									name: "Warm Tan"
								},
								{
									color:"#5a5760",
									name: "Slate Gray"
								},
								{
									color:"#36454c",
									name: "Charcoal Blue"
								},
								{
									color:"#000000",
									name: "Jet Black"
								},
								{
									color:"#e4e4e4",
									name: "Pale Gray"
								}
							],
							patterns: [
								{
									pattern: window.resoursesPath+"/images/patterns/img-8.jpg",
									name: "Pattern 1"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-9.jpg",
									name: "Pattern 3"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-10.png",
									name: "Pattern 4"
								}
							]
						}
					}
				}, {
					name: "Model 2",
					image: window.resoursesPath+"/images/choices/image_20.png",
					model: window.homeUrl+"?model=model-2",
					parts: {
						top: [
							{
								name: "Base",
								model: "",
								preview: window.resoursesPath+"/images/choices/top-2.png"
							},
							{
								name: "Round",
								model: window.homeUrl+"?model=top-2",
								preview: window.resoursesPath+"/images/choices/top-1.png"
							}
						],
						bottom: [
							{
								groupName: "No Fixed Base",
								models: [
									{
										name: "Base",
										model: "",
										preview: window.resoursesPath+"/images/choices/bottom-3.jpg"
									},
									{
										name: "Round",
										model: window.homeUrl+"?model=bottom-2",
										preview: window.resoursesPath+"/images/choices/bottom-2.jpg"
									}
								]
							},
							{
								groupName: "Fixed Base",
								models: [
									{
										name: "Bold Down Base",
										model: window.homeUrl+"?model=bottom-1",
										preview: window.resoursesPath+"/images/choices/bottom-1.jpg"
									}
								]
							}
						]
					},
					colors: {
						top: {
							colors: [
								{
									color: "#e76f18",
									name: "Pumpkin Orange"
								},
								{
									color: "#38191f",
									name: "Dark Burgundy"
								},
								{
									color: "#592f37",
									name: "Deep Maroon"
								},
								{
									color: "#f3c501",
									name: "Golden Yellow"
								},
								{
									color: "#e4e4e4",
									name: "Light Gray"
								}
							],
							patterns: [
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-6.jpg",
									name: "Pattern 1"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-7.jpg",
									name: "Pattern 2"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-1.jpg",
									name: "Pattern 3"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-last-3.jpg",
									name: "Pattern 4"
								},
							]
						},
						main: {
							colors: [
								{
									color: "#aea5a6",
									name: "Light Taupe"
								},
								{
									color: "#9b7b66",
									name: "Warm Tan"
								},
								{
									color: "#5a5760",
									name: "Slate Gray"
								},
								{
									color: "#000000",
									name: "Jet Black"
								},
								{
									color: "#e4e4e4",
									name: "Pale Gray"
								}
							],
							patterns: [
								{
									pattern: window.resoursesPath+"/images/patterns/img-8.jpg",
									name: "Pattern 1"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-9.jpg",
									name: "Pattern 3"
								},
								{
									pattern: window.resoursesPath+"/images/patterns/img-10.png",
									name: "Pattern 4"
								}
							]
						}
					}
				}
			]
		};

		setItems(newItems);
	}, []);

	return (
		<section className="section-configurator-alt js-configurator">
			<div className="section__inner">
				<div className="section__main">
					<div className="section__head"></div>

					<div className="section__media">
						<Configurator
							model={model}
							selectedData={selectedData}

							setModel={setModel}

							modelTop={modelTop}
							setModelTop={setModelTop}

							modelTopColor={modelTopColor}
							setModelTopColor={setModelTopColor}

							modelStructureColor={modelStructureColor}
							setModelStructureColor={setModelStructureColor}

							modelBottom={modelBottom}
							setModelBottom={setModelBottom}
						/>
					</div>
				</div>
				<div className="section__aside">
					<div className="widget-configurator">
						<div className="widget__head">
							<Titles
								items={items}
								currentStep={currentStep}
								titles={items.titles}
								model={model}
								modelTop={modelTop}
								modelTopColor={modelTopColor}
								modelStructureColor={modelStructureColor}
								modelBottom={modelBottom}
							/>
						</div>

						<div className="widget__body">
							<ChoiceDistributor
								items={items}
								model={model}
								setModel={setModel}
								selectedData={selectedData}
								setSelectedData={setSelectedData}
								modelTop={modelTop}
								setModelTop={setModelTop}
								modelStructureColor={modelStructureColor}
								setModelStructureColor={setModelStructureColor}
								modelTopColor={modelTopColor}
								setModelTopColor={setModelTopColor}
								modelBottom={modelBottom}
								setModelBottom={setModelBottom}
								currentStep={currentStep}
								setCurrentStep={setCurrentStep}
							/>
						</div>

						<WidgetActions
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							items={items}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ConfiguratorPage;