import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

function ChoiceTopModel({ items, model, setModel, setSelectedData, setModelTopColor, setModelStructureColor}) {
	const [activeIndex, setActiveIndex] = useState(() => {});

	
	const updateModelData = (index, item) => {
		setActiveIndex(index);

		setModel(prevState => ({
			...prevState,
			name: item.name,
			drawing: item.model
		}));

		setModelTopColor(prevState => ({
			...prevState,
			color: item.colors.top.colors[0].color,
			name: item.colors.top.colors[0].name
		}));

		setModelStructureColor(prevState => ({
			...prevState,
			color: item.colors.main.colors[0].color,
			name: item.colors.main.colors[0].name
		}));

		setSelectedData(prevState => ({
			...prevState,
			...item
		}));

	}

	if (!items || !items.models) {
		return null;
	}

	return (
		<div className="choices">
			<ul>
				{items.models.map((item, index) => (
					<li key={index}>
						<a

							onClick={(e) => {
								e.preventDefault();
								updateModelData(index, item);
							}}
							className={classNames('preview decor-checkbox', { 'is-active': activeIndex === index })}
							href={item.model}
						>
							<picture className="preview__media">
								<img src={item.image} alt={item.name} />
							</picture>

							<div className="preview__content">
								<h6>{item.name}</h6>
							</div>
						</a>
					</li>
				))}
			</ul>
		</div >
	);
}

export default ChoiceTopModel;