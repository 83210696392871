import React from 'react';
import classNames from 'classnames';
import ChoiceModel from './ChoiceModel';
import ChoiceTopModel from './ChoiceTopModel';
import ChoiceBottomModel from './ChoiceBottomModel';
import ChoiceColorPatterns from './ChoiceColorPatterns';
import ChoiceColorStructure from './ChoiceColorStructure';

function ChoiceDistributor({
	items,
	model,
	setModel,
	selectedData, setSelectedData,
	modelTop, setModelTop,
	modelTopColor, setModelTopColor,
	modelStructureColor, setModelStructureColor,
	modelBottom, setModelBottom,
	currentStep, setCurrentStep

}) {
	return (
		<>
			<div className={classNames('choice-holder', { 'is-visible': currentStep == 1 })}>
				<ChoiceModel
					items={items}
					model={model}
					setModel={setModel}
					setSelectedData={setSelectedData}
					modelTopColor={modelTopColor}
					setModelTopColor={setModelTopColor}
					setModelStructureColor={setModelStructureColor}
					setModelBottom={setModelBottom}
					modelBottom={modelBottom}
				/>
			</div>


			<div className={classNames('choice-holder', { 'is-visible': currentStep == 2 })}>
				<ChoiceTopModel
					items={items}
					model={model}
					selectedData={selectedData}
					modelTop={modelTop}
					setModelTop={setModelTop}
				/>
			</div>

			<div className={classNames('choice-holder', { 'is-visible': currentStep == 3 })}>
				<ChoiceColorPatterns
					items={items}
					selectedData={selectedData}
					modelTopColor={modelTopColor}
					setModelTopColor={setModelTopColor}
				/>
			</div>

			<div className={classNames('choice-holder', { 'is-visible': currentStep == 4 })}>
				<ChoiceColorStructure
					items={items}
					selectedData={selectedData}
					modelStructureColor={modelStructureColor}
					setModelStructureColor={setModelStructureColor}
				/>
			</div>

			<div className={classNames('choice-holder', { 'is-visible': currentStep == 5 })}>
				<ChoiceBottomModel
					items={items}
					selectedData={selectedData}
					
					setModelBottom={setModelBottom}
					
				/>
			</div>
		</>
	);
}

export default ChoiceDistributor;