import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

function ChoiceColorStructure({ selectedData, setModelStructureColor }) {
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {//Reset Selection on model change 
		if (selectedData.length === 0) { return }
		setActiveIndex(0)
	}, [selectedData]);

	const onClickHandler = (index, color, fabric, name) => {
		setActiveIndex(index);
		setModelStructureColor(prevState => ({
			...prevState,
			color: color,
			name: name,
			pattern:fabric
		}))
	}

	if (!selectedData || !selectedData.parts || !selectedData.parts.top || !Array.isArray(selectedData.parts.top)) {
		return null;
	}
	if (selectedData.parts.top.length === 0) {
		return null;
	}

	return (
		<div className="choices choices--color">
			<ul>
				{selectedData.colors.main.colors.map((item, index) => (
					<li key={index}>
						<a
							onClick={(e) => {
								e.preventDefault();
								onClickHandler(index, item.color, null, item.name);
							}}

							className={classNames('decor-checkbox decor-checkbox--black', { 'is-active': activeIndex === index })}
							href="#"
							data-color={item.color}
							style={{ backgroundColor: item.color }}
						>
						</a>
					</li>
				))}

				{selectedData.colors.main.patterns.map((item, index) => (
					<li key={index}>
						<a
							onClick={(e) => {
								e.preventDefault();

								fetch(item.pattern)
									.then(response => response.blob())
									.then(blob => {
										const imageUrl = URL.createObjectURL(blob);
										onClickHandler("a" + index, null, imageUrl, item.name);
									})
									.catch(error => {
										console.error("Error loading image:", error);
									});
							}}

							className={classNames('decor-checkbox decor-checkbox--black', { 'is-active': activeIndex === "a" + index })}
							href={item.pattern}
						>
							<img src={item.pattern} alt={`Option ${index + 1}`} />
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

export default ChoiceColorStructure;