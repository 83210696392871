import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

function ChoiceTopModel({model, selectedData, modelTop, setModelTop}) {
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
        setActiveIndex(0);
    }, [model]);

	const handleModelSelection = (path, name, index, selectedData) => {
		setActiveIndex(index);

		setModelTop(prevState => ({ 
			...prevState, 
			name: name, 
			drawing: path 
		}));
	}


	if (!selectedData || !selectedData.parts || !selectedData.parts.top || !Array.isArray(selectedData.parts.top)) {
		return null;
	}

	if (selectedData.parts.top.length === 0) {
		return null;
	}
	
	return (
		<div className="choices">
			<ul>
				{selectedData.parts.top.map((item, index) => (
					<li key={index}>
						<a
							onClick={(e) => {
								e.preventDefault();
								handleModelSelection(item.model, item.name, index, selectedData)
							}}
							className={classNames('preview decor-checkbox', { 'is-active': activeIndex === index })}
							href={item.model}
						>
							<picture className="preview__media">
								<img src={item.preview} alt={item.name} />
							</picture>

							<div className="preview__content">
								<h6>{item.name}</h6>
							</div>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

export default ChoiceTopModel;