import React, { useCallback } from 'react';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

const ViewManager = ({ views, controls, camera}) => {
	const moveToPoint = (position, target) => {
		if (!camera || !controls) return;

		new TWEEN.Tween(camera.position)
			.to({ x: position.x, y: position.y, z: position.z }, 1000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => controls.update())
			.start();

		new TWEEN.Tween(controls.target)
			.to({ x: target.x, y: target.y, z: target.z }, 1000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => controls.update())
			.start();
	};

	const smoothZoom = (direction) => {
		if (!camera || !controls) return;

		const ZOOM_FACTOR = 0.8;
		const factor = direction ? ZOOM_FACTOR : 1 / ZOOM_FACTOR;

		new TWEEN.Tween(camera.position)
			.to({
				x: camera.position.x * factor,
				y: camera.position.y * factor,
				z: camera.position.z * factor
			}, 200)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => controls.update())
			.start();
	};

	const moveCamera = (ver, hor) => {
		if (!camera || !controls) return;

		const { x, y, z } = camera.position;
		const r = Math.sqrt(x * x + y * y + z * z);
		const c2 = Math.sqrt(x * x + z * z);
		const lastCameraLookAt = getCameraLookAtPoint(camera, r);
		let ozxAng = Math.atan2(x, z) * (180 / Math.PI);
		if (ozxAng < 0) ozxAng += 360;

		const toozxAng = ozxAng + ver;
		const posX = Math.sin((toozxAng) * (Math.PI / 180)) * c2;
		const posZ = Math.cos((toozxAng) * (Math.PI / 180)) * c2;

		new TWEEN.Tween(camera.position)
			.to({ x: posX, y: camera.position.y + hor, z: posZ }, 1000)
			.easing(TWEEN.Easing.Quadratic.Out)
			.onUpdate(() => camera.lookAt(lastCameraLookAt))
			.start();
	};

	const getCameraLookAtPoint = (camera, distance) => {
		const direction = new THREE.Vector3();
		camera.getWorldDirection(direction);
		return new THREE.Vector3().copy(camera.position).add(direction.multiplyScalar(distance));
	};

    return (
        <div className="controls">
            <ul>
                {views.map((view, index) => (
                    <li key={index} onClick={() => moveToPoint(view.position, view.target)}>
                        <img src={view.thumbnail} alt={`View ${index + 1}`} />
                    </li>
                ))}
            </ul>

            <button className="btn-move btn-move--out" onClick={() => smoothZoom(false)}>Zoom Out</button>
            <button className="btn-move btn-move--in" onClick={() => smoothZoom(true)}>Zoom In</button>

            <div className="controls__move">
                <button className="btn-move btn-move--up" onClick={() => moveCamera(0, 10)}>Up</button>
                <button className="btn-move btn-move--left" onClick={() => moveCamera(-10, 0)}>Left</button>
                <button className="btn-move btn-move--right" onClick={() => moveCamera(10, 0)}>Right</button>
                <button className="btn-move btn-move--down" onClick={() => moveCamera(0, -10)}>Down</button>
            </div>
        </div>
    );
};

export default ViewManager;