import React from 'react';
import ReactDOM from 'react-dom/client';
import ConfiguratorPage from './components/ConfiguratorPage';
import './bundle.css';
import './new-styles.css';

const root =  ReactDOM.createRoot(document.getElementById('react-root'));
root.render(
  <React.StrictMode>
    <ConfiguratorPage/>
  </React.StrictMode>
);
