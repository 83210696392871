import React, { useEffect } from 'react';
import classNames from 'classnames';

function WidgetActions({ currentStep, setCurrentStep}) {
	const allSteps = 5; /* ??items.length */

	const handleNextButton = () => {
		if (currentStep < allSteps) {
			setCurrentStep(currentStep += 1)
		}else {

		}
	}
	return (
		<div className="widget__actions">
			<div className="widget__actions-inner">
				{(currentStep > 1) && (
					<a
						href="#"

						className={classNames('btn btn--alt', { '': true })}
						onClick={(e) => {
							e.preventDefault();
							setCurrentStep(currentStep -= 1)
						}}
					>
						Back
					</a>
				)}

				<a
					href="#"
					className="btn"
					onClick={(e) => {
						e.preventDefault();
						handleNextButton();
					}}
				>
					{currentStep < allSteps ? (
						"Next Step"
					) : (
						"Submit Your Request"
					)}
				</a>
			</div>
		</div>
	);
}

export default WidgetActions;


