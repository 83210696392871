import React from 'react';

function Titles({items, currentStep, titles, model,  modelTop,  modelTopColor,  modelStructureColor,  modelBottom}) {
	if (!titles ) {
		return null;
	}
	const models = [model,  modelTop,  modelTopColor,  modelStructureColor,  modelBottom]
	return (
		<>
			<h5>Step {currentStep}</h5>
			<h4>{titles[currentStep-1]}</h4>

			{models.map((item, index) => (
				currentStep === index+1 && item.name !== "" && (
                    <h6 key={index}>{item.name}</h6>
                )
            ))}
		</>
	);
}

export default Titles;